







































































































































































































































































































































































import { API } from "@/shared/api";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
import { enumeracion } from "@/shared/dtos/enumeracion";
import { incidencia } from "@/shared/dtos/incidencias/incidencia";
import { Usuario } from "@/shared/dtos/usuario";
import { ssmPermissionService } from "@/shared/services/PermissionService";
import departamentoModule from "@/store/modules/departamento-module";
import empresaModule from "@/store/modules/empresa-module";
import enumeracionModule from "@/store/modules/enumeracion-module";
import incidenciaModule from "@/store/modules/incidencia-module";
import invetarioModule from "@/store/modules/invetario-module";
import proyectoModule from "@/store/modules/proyecto-module";
import usuarioModule from "@/store/modules/usuario-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    DxDateBox: () => import("devextreme-vue/date-box"),
    HTMLEditor: () => import("@/components/html-editor/html-editor.vue"),
    HandlerChat: () => import("@/components/Chat/HandlerChat.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    XBtnOperativeActiveInactive: () =>
      import("@/components/TypeView/x-btnOperativeActiveInactive.vue"),
    DocumentsHandler: () =>
      import("@/views/proyectos/Documentos/DocumentsHandler.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class IncidenciasFormulario extends Vue {
  @Prop({ default: -1 }) id_incidencia!: number;
  @Prop({ default: -1 }) id_proyecto!: number;
  @Prop({ default: -1 }) id_tarea!: number;

  public enums_prioridades: enumeracion[] = [];
  public enums_estados: enumeracion[] = [];
  public enums_categorias: enumeracion[] = [];
  public show_chat: boolean = false;
  public mensajes_not_read: number = 0;
  public expand: boolean = true;
  public select_tab = "tab-1";

  created() {
    if (this.is_new()) {
      incidenciaModule.onGetincidencia(new incidencia());
      this.datasource.fecha_inicio = new Date();
    } else {
      incidenciaModule.getincidencia(this.id_incidencia);
      this.get_files();
    }

    empresaModule.getempresas();
    departamentoModule.getdepartamentos();
    usuarioModule.getusuarios();
    invetarioModule.getinvetarios();
    /*
    3	Prioridades de las incidencias
    4	Estados de las inciencias
    5	Categorías de las incidencias
    */
    enumeracionModule.getenumeraciones_fromtipo(3).then((x: enumeracion[]) => {
      this.enums_prioridades = x;
    });
    enumeracionModule.getenumeraciones_fromtipo(4).then((x: enumeracion[]) => {
      this.enums_estados = x;
      if (this.is_new()) {
        let numbers: number[] = x.map((k) => k.orden);
        let orden_minima = Math.min.apply(Math, numbers);
        this.datasource.id_estado = x.find((k) => k.orden === orden_minima)!.id;
      }
    });
    enumeracionModule.getenumeraciones_fromtipo(5).then((x: enumeracion[]) => {
      this.enums_categorias = x;
    });

    if (this.$route.params.id === undefined && this.id_tarea > -1) {
      proyectoModule.getusuariosTareaProyecto(this.id_tarea);
    }
  }

  get datasource() {
    return incidenciaModule.incidencia;
  }

  get usuarios() {
    if (
      //@ts-ignore
      usuarioModule.usuarios_proyecto_cache.id_pro ===
      Number.parseInt(this.$route.params.id)
    ) {
      //@ts-ignore
      return usuarioModule.usuarios_proyecto_cache.usuarios;
    }
    if (this.$route.params.id === undefined && this.id_tarea > -1) {
      return proyectoModule.usuarios;
    }

    return usuarioModule.usuarios.filter((x: Usuario) => x.tecnico === true);
  }

  get empresas() {
    return empresaModule.empresas;
  }
  get departamentos() {
    return departamentoModule.departamentos;
  }
  get inventarios() {
    return invetarioModule.invetarios.filter((x) => x.id_sub_inventario === 0);
  }

  public get files() {
    return incidenciaModule.files;
  }

  @Watch("select_tab")
  public wselect_tab() {
    if (this.select_tab === "tab-2") {
      this.get_files();
    }
  }

  public Save() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.id_proyecto === -1) {
          this.datasource.id_proyecto = 0;
        } else {
          this.datasource.id_proyecto = this.id_proyecto;
        }

        if (this.id_tarea === -1) {
          this.datasource.id_tarea = 0;
        } else {
          this.datasource.id_tarea = this.id_tarea;
        }

        if (this.is_new()) {
          incidenciaModule
            .guardarincidencia(incidenciaModule.incidencia)
            .then(() => this.finish_save());
        } else {
          incidenciaModule
            .modificarincidencia(incidenciaModule.incidencia)
            .then(() => this.finish_save());
        }
      }
    });
  }

  mensajes_no_leidos(mensajes: number) {
    this.mensajes_not_read = mensajes;
  }
  finish_save() {
    this.$emit("close");
  }

  HasPermission(permiso: string) {
    return ssmPermissionService.hasPermission(permiso);
  }

  public get_files() {
    if (this.datasource.id === null) {
      return;
    }
    return incidenciaModule.getfiles(this.datasource.id).catch(() => []);
  }

  public is_new() {
    return Number.parseInt(UtilsString.ValueOf(this.id_incidencia)) === -1;
  }

  public Geturl() {
    return API.incidencia + "/documents/" + this.datasource.id;
  }

  public delete_file(file: document_fileDto) {
    incidenciaModule
      .eliminar_documento({
        object: this.datasource,
        doc: file,
      })
      .then(() => {
        this.get_files();
      });
  }

  public downlod_file(file: document_fileDto) {
    window.open(
      API.webApiBaseIncidencias + this.datasource.id + "/" + file.name
    );
  }
}
