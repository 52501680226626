import { BaseDto } from "@/shared/dtos/base-dto";

export class invetario extends BaseDto {
  public nombre!: string;
  public n_serie!: string;
  public modelo!: string;
  public p_n!: string;
  public dispositivo!: string;
  public stock!: number;
  public ubicacion!: string;
  public comentarios_inv!: string;
  public descripcion_inv!: string;
  public fechainicio_soporte!: Date;
  public fechafin_soporte!: Date;
  public id_categroria!: number;
  public id_inventario!:number;
  public id_sub_inventario!:number;
  public ano_fabricacion!:number;
}
